<template>
  <div class="company-info">
    <PageTitle @btnClick="dialog.edit = true" />
    <div class="container">
      <el-form label-width="200px" label-position="left">
        <el-form-item label="統一編號">{{ contactMenuInfo.taxId }}</el-form-item>
        <el-form-item label="公司名稱">{{ contactMenuInfo.name }}</el-form-item>
        <el-form-item
          v-for="(row) in sortSchema"
          :key="row.text"
          :label="row.text"
        >
          {{ getInfoData(row.key) }}
        </el-form-item>
      </el-form>
    </div>

    <EditCompanyDialog
      v-if="dialog.edit"
      :schema="dataSchema"
      :companyData="contactMenuInfo"
      @close="dialog.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import EditCompanyDialog from '@/views/Company/components/EditCompanyDialog.vue'
// api
import { GetShopContactMenuSchema } from '@/api/company/contactMenu'
import { get } from 'lodash'

import { mapGetters } from 'vuex'
export default {
  name: 'CompanyInfo',
  components: { EditCompanyDialog },
  props: {
    contactMenuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dataSchema: {},
    dialog: {
      edit: false,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    sortSchema () {
      const list = []
      for (const key in this.dataSchema) {
        list.push({
          key,
          ...this.dataSchema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)
      return list
    },
  },
  async mounted () {
    await this.getShopContactMenuSchema()
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    showErrorMsg (err) {
      if (err) {
        this.$message.error(err)
        return true
      }
      return false
    },
    async getShopContactMenuSchema () {
      const [res, err] = await GetShopContactMenuSchema({
        shopId: this.shop,
      })
      if (this.showErrorMsg(err)) return
      this.dataSchema = res.customize
    },
    getInfoData (key) {
      return get(this.contactMenuInfo, `customize.${key}`) || '-'
    },
  },
}
</script>

<style lang="postcss" scoped>
.container {
  @apply bg-white w-full max-w-full px-[24px] py-[30px] rounded-sm;
}
</style>
