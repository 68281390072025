<template>
  <div class="company-info-block">
    <div class="section-info">
      <p class="company-name">{{ contactMenuInfo.name }}</p>
      <p class="company-number">{{ contactMenuInfo.taxId }}</p>
    </div>
    <el-divider direction="vertical" class="h-auto divider" />
    <div class="section-info">
      <div class="time-info">
        <span class="label">建立時間</span>
        <span class="value">{{ formatDate(contactMenuInfo.createdAt, 'YYYY-MM-DD HH:mm') }}</span>
      </div>
      <div class="time-info">
        <span class="label">更新時間</span>
        <span class="value">{{ formatDate(contactMenuInfo.updatedAt, 'YYYY-MM-DD HH:mm') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date'
export default {
  name: 'CompanyInfoBlock',
  props: {
    contactMenuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatDate
  }
}
</script>

<style lang="scss" scoped>
.company-info-block {
  @apply bg-white rounded-[6px] flex p-[15px] mb-[16px];
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.divider {
  margin: 0 20px !important;
}

.section-info {
  @apply py-[6px] flex flex-col gap-[14px];
}

.company-name {
  @apply font-bold text-[18px] leading-[24px] tracking-[1px];
}
.company-number {
  @apply font-medium text-[16px] leading-[24px] tracking-[0.5px];
}

.time-info {
  .label {
  @apply text-[14px] text-gray-80 mr-[16px];
  }
  .value {
    @apply font-medium ;
  }
}
</style>
