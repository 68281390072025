<template>
  <div class="company-detail">
    <PageTitle title="企業資訊" icon="chevron_left" hideBtn @iconClick="$router.push({ name: 'CompanyContactMenu' })" />
    <div v-loading="loading">
      <CompanyInfoBlock :contactMenuInfo="contactMenuInfo" />
    </div>
    <MemberPageTabs>
      <el-tabs v-model="curTab" type="card" @tab-click="tabChange">
        <el-tab-pane
          label="聯絡人名單"
          name="contactMenu"
        >
          <div v-loading="loading">
            <ContactMenu v-if="contactMenuInfo.id && curTab === 'contactMenu'" :companyId="queryCompanyId" :contactMenuInfo="contactMenuInfo" />
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="企業資訊"
          name="companyInfo"
        >
          <div v-loading="loading">
            <CompanyInfo
              v-if="contactMenuInfo.id && curTab === 'companyInfo'"
              :companyId="queryCompanyId"
              :contactMenuInfo="contactMenuInfo"
              @refresh="refresh"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </MemberPageTabs>
  </div>
</template>

<script>
import MemberPageTabs from '@/components/Tabs/MemberPageTabs'
import CompanyInfoBlock from './components/CompanyInfoBlock.vue'
import ContactMenu from './components/ContactMenu.vue'
import CompanyInfo from './components/CompanyInfo.vue'
// api
import { FindShopContactMenu } from '@/api/company/contactMenu'
import { mapGetters } from 'vuex'
export default {
  name: 'CompanyDetail',
  components: { CompanyInfoBlock, MemberPageTabs, ContactMenu, CompanyInfo },
  data: () => ({
    loading: false,
    curTab: 'contactMenu',
    contactMenuInfo: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    queryTab () {
      return this.$route.query.tab
    },
    queryCompanyId () {
      return this.$route.query.companyId
    },
  },
  async mounted () {
    this.$store.commit('app/SET_SIDEBAR', false)
    if (this.queryTab) this.curTab = this.queryTab
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.findShopContactMenuInfo()
      this.loading = false
    },
    tabChange () {
      this.$router.push({
        name: 'CompanyDetail',
        query: {
          companyId: this.queryCompanyId,
          tab: this.curTab,
        },
      })
    },
    onRowEdit () {},
    onRowDelete () {},
    async findShopContactMenuInfo () {
      const [res, err] = await FindShopContactMenu({
        shopId: this.shop,
        id: this.queryCompanyId,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.contactMenuInfo = res
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
